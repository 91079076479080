import React, { useState, useEffect, useCallback } from 'react';
import './App.css'; // Import the default styles
import { fetchAllPicks, fetchData } from './services/api';
import { doc, getFirestore, collection, getDocs, getDoc } from 'firebase/firestore';

const B10Leaders = ({ onPageChange }) => {
    const [data, setPicks] = useState([]); // State to store the fetched data
    const [board, setBoard] = useState({}); // State to store the leaderboard data
    const [loading, setLoading] = useState(true); // State to store the leaderboard data
    const [displayNames, setDisplayNames] = useState({}); // State to store the leaderboard data
    const [pics, setPics] = useState({}); // State to store the leaderboard data
    const [colors, setColors] = useState({}); // State to store the leaderboard data
    const [gsf, setGamesSoFar] = useState(0); // State to store the leaderboard data

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `https://site.api.espn.com/apis/site/v2/sports/football/college-football/scoreboard?dates=2024&limit=1000`;
                const response = await fetch(url);
                const result = await response.json();

                const firestore = getFirestore();
                const usersCollection = collection(firestore, 'Users');
                const usersSnapshot = await getDocs(usersCollection);
                const users = usersSnapshot.docs.map(doc => doc.data());
                
                const picksCollection = collection(firestore, 'B10Picks');
                const picksSnapshot = await getDocs(picksCollection);
                const picks = picksSnapshot.docs.map(doc => doc.data());

                const filteredGames = result.events.filter(game => 
                    game.competitions[0].conferenceCompetition && 
                    game.competitions[0].competitors[0].team.conferenceId === "5"
                );


                // Step 1: Convert games object to a more accessible format
                const gamesResults = Object.values(filteredGames).reduce((acc, game) => {
                    console.log(game);
                    var winner = "";
                    if(game.competitions[0].competitors[0].winner){
                        winner = game.competitions[0].competitors[0].team.name;
                        acc[game.id] = winner;
                    }
                    else if(game.competitions[0].competitors[1].winner){
                        winner = game.competitions[0].competitors[1].team.name;
                        acc[game.id] = winner;
                    }
                    
                    return acc;
                }, {});

                setGamesSoFar(Object.keys(gamesResults).length);

                // Step 2: Prepare user stats
                const userStats = users.map(user => {
                    const userPicks = picks.filter(pick => pick.Email === user.Email);
    
                    let gamesPredicted = 0;
                    let gamesCorrect = 0;
    
                    userPicks.forEach(pick => {
                        const parsedPicks = JSON.parse(pick.Picks);
                        for (const gameId in parsedPicks) {

                            if(gamesResults[gameId] !== undefined){
                                gamesPredicted += 1;
                                if (gamesResults[gameId] === parsedPicks[gameId].pick) {
                                    gamesCorrect += Math.abs(50 - parsedPicks[gameId].val);
                                }
                                else{
                                    gamesCorrect -= Math.abs(50 - parsedPicks[gameId].val);
                                }
                            }
                            
                        }
                    });

                    return {
                        Color: user.Color,
                        Name: user.Name,
                        Icon: user.Icon,
                        email: user.Email,
                        Order: user.Order,
                        gamesPredicted,
                        gamesCorrect
                    };
                });
                console.log(userStats);
                setBoard(userStats);

                setLoading(false);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the function to fetch data
    }, []);

    const currentDateTime = new Date().toLocaleString([], { dateStyle: 'medium', timeStyle: 'short' });

    return (
        <div className="page">
            <div className="leaderboard">

                <h1>Leaderboard</h1>
                {true ? (
                    <div>
                <h2>2024 Season</h2>
                
                <table>
                    <thead>
                        <tr>
                            <th>RK</th>
                            <th>PLAYER</th>
                            <th>ACCURACY</th>
                            <th>GP</th>
                        </tr>
                    </thead>
                    <tbody>
                      {loading && Object.keys(board).length === 0 ? (
                        <tr><td colSpan="4">Loading...</td></tr>
                      ) : (
                        Object.values(board)
                            .filter((user) => {
                              return user["gamesPredicted"] > 0;
                          })
                          .sort((a, b) => {
                              const pointsA = (a["gamesCorrect"] + a["gamesPredicted"] * 50 + (gsf - a["gamesPredicted"]) * 50)/gsf;
                              const pointsB = (b["gamesCorrect"] + b["gamesPredicted"] * 50 + (gsf - b["gamesPredicted"]) * 50) / gsf;
                              
                              const pointsComparison = pointsB - pointsA;

                              if (pointsComparison !== 0) {
                                  return pointsComparison;
                              }

                              const pointsComparison2 = b["gamesPredicted"] - a["gamesPredicted"];
                              if (pointsComparison2 !== 0) {
                                  return pointsComparison2;
                              }

                              // If points are equal, sort by "Order"
                              const orderA = a["Order"] !== undefined && a["Order"] !== null ? a["Order"] : Number.MIN_SAFE_INTEGER;
                              const orderB = b["Order"] !== undefined && b["Order"] !== null ? b["Order"] : Number.MIN_SAFE_INTEGER;
                              const orderComparison = orderB - orderA;
                              if (orderComparison !== 0) {
                                  return orderComparison;
                              }

                              return a["Name"].localeCompare(b["Name"]);
                          })
                          .map((user, index, array) => {
                            const rank = index === 0 ? 1 : array[index - 1]["Points"] > user["Points"] ? index + 1 : index + 1;
                             // const accuracy = (user["gamesCorrect"] + Math.max(user["gamesPredicted"], 0) * 50) / Math.max(gsf, 1);
                              const accuracy = (user["gamesCorrect"] + 50 * user["gamesPredicted"] + (gsf - user["gamesPredicted"]) * 50) / gsf;
                              console.log(gsf);
                              if(user["gamesPredicted"] > 0){
                                return (
                                      <tr key={user["Name"]}>
                                        <td>{rank}</td>
                                        <td>
                                          <span>
                                            <span className={user["Color"]}>
                                              <img alt="Profile" src={`${user["Icon"]}.png`} />
                                            </span>
                                            {user["Name"]}
                                          </span>
                                        </td>
                                        <td>{gsf > 0 ? accuracy.toFixed(1) + "%" : "-"}</td>
                                        <td>{user["gamesPredicted"]}</td>
                                      </tr>
                                    );
                              }
                              else{
                                  return null;
                              }
                            
                          })
                      )}
                </tbody>


                </table>
                </div>
                )
                    : <div className="">Season leaderboard will appear after Week 1</div>}
            </div>

        </div>
    );
};

export default B10Leaders;