import React, { useEffect, useState, useRef } from 'react';
import './Auction.css';
import { doc, getFirestore, collection, getDocs, getDoc, addDoc, updateDoc, deleteField, setDoc  } from 'firebase/firestore';

const QBxLeaders = () => {

   
    const [board, setBoard] = useState({});
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const firestore = getFirestore();
                const usersCollection = collection(firestore, 'Users');
                const snapshot = await getDocs(usersCollection);
                const fetchedData = snapshot.docs.map(doc => doc.data());
                const defaultObject = { cash: -1, myQBs: {} }

                const qbDataPromises = fetchedData.map(async (user) => {
                    const documentId = user.Email;

                    if (!documentId) {
                        return defaultObject;
                    }

                    const QBxRef = doc(firestore, 'QBx', documentId);
                    try {
                        const qbDoc = await getDoc(QBxRef);

                        if (qbDoc.exists()) {

                            return qbDoc.data();
                        } else {
                            return defaultObject;
                        }
                    } catch (error) {
                        return defaultObject;
                    }
                });

                const qbData = await Promise.all(qbDataPromises);
                console.log(qbData);
                const combinedData = fetchedData.map((user, index) => ({
                    ...user,
                    qbData: qbData[index]
                }));

                const dataWithTotalPrices = combinedData.map(user => {
                    // Extract prices from myQBs object and sum them

                    var totalPrice = 0;
                    if (user.qbData.myQBs !== undefined && Object.values(user.qbData.myQBs).length > 0) {
                        totalPrice = Object.values(user.qbData.myQBs).reduce((sum, price) => sum + (price || 0), 0);
                    }


                    return {
                        ...user,
                        totalPrice // Add totalPrice to the user data
                    };
                });

                // Update the state with combined data
                setBoard(dataWithTotalPrices);

            } catch (error) {
                console.log(error);
            }
        };

        // Call the function to fetch data
        fetchUserData();
    }, []);

    return (
        <>
            <div className="page">
                <div className="leaderboard">

                    <h1>Leaderboard</h1>

                    <div>

                        <table>
                            <thead>
                                <tr>
                                    <th>RK</th>
                                    <th>PLAYER</th>
                                    <th>PTS</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Object.values(board)
                                        .map(user => ({
                                            ...user,
                                            totalMoney: user.qbData.cash + user.totalPrice
                                        }))
                                        .sort((a, b) => {
                                            // First, sort by "Points", treating missing values as 0
                                            const moneyComparison = b.qbData.cash - a.qbData.cash;
                                            if (moneyComparison !== 0) {
                                                return moneyComparison;
                                            }

                                            // If points are equal, sort by "Order"
                                            const orderA = a["Order"] !== undefined && a["Order"] !== null ? a["Order"] : Number.MIN_SAFE_INTEGER;
                                            const orderB = b["Order"] !== undefined && b["Order"] !== null ? b["Order"] : Number.MIN_SAFE_INTEGER;
                                            const orderComparison = orderB - orderA;
                                            if (orderComparison !== 0) {
                                                return orderComparison;
                                            }

                                            // If both points and order are equal, sort by "Icon"
                                            return a["Name"].localeCompare(b["Name"]);
                                        })
                                        
                                        .map((user, index, array) => {
                                            if(user.qbData.cash >= 0){
                                                const rank = index === 0 ? 1 : array[index - 1].totalMoney > user.totalMoney ? index + 1 : index + 1;

                                                return (
                                                    <tr key={user.Name}>
                                                        <td>{rank}</td>
                                                        <td>
                                                            <span>
                                                                <span className={user.Color}>
                                                                    <img alt="Profile" src={`${user.Icon}.png`} />
                                                                </span>
                                                                {user.Name}
                                                            </span>
                                                        </td>
                                                        <td>{user.qbData.cash}</td>
                                                    </tr>
                                                );
                                            }
                                            
                                        })
                                }
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </>)
}

const Auction = () => {
    const today = new Date();

    // Create a date object for September 3rd of the current year
    const seasonStart = new Date(2024, 8, 3); // Month is 0-indexed (0 = January, 8 = September)

    // Calculate the difference in milliseconds
    const diffInMs = today - seasonStart;

    // Convert the difference from milliseconds to days
    const msInDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.floor(diffInMs / msInDay);

    var weekNumber = Math.ceil(diffInDays / 7);

    var type = 2;
    if (weekNumber < 1) {
        weekNumber = 1; type = 2;
    }

    const email = JSON.parse(localStorage.getItem('profile')).Email;

    var y = 2024;

    if (email == "benjaminalashari@gmail.com"// || email == "johnalashari@gmail.com" || email == "dyllack@gmail.com")
    ){
       /* weekNumber = 1;
        y = 2023;
        type = 2;*/
    }
    
    const [quarterbacks, setQuarterbacks] = useState({});
    const [loading, setLoading] = useState(true);
    const [weeks, setWeeks] = useState(weekNumber);
     const [year, setYear] = useState(y);
    const [buttonText, setButtonText] = useState([]);
    const [toggle, setToggle] = useState([]);
    const [filter, setFilter] = useState("all");
    const [cash, setCash] = useState("");
     const [savedProfile, setSavedProfile] = useState(JSON.parse(localStorage.getItem('profile')) || {});
     const [info, setInfo] = useState(null);
     const [myQBs, setMyQBs] = useState([]);

    const extractWeekNumber = (url) => {
        if (typeof url !== 'string') {
            console.error('Invalid URL:', url);
            return null;
        }
        const weekNumberMatch = url.match(/weeks\/(\d+)/);
        return weekNumberMatch ? parseInt(weekNumberMatch[1], 10) : null;
    };
    const extractAthleteId = (url) => {
        if (typeof url !== 'string') {
            console.error('Invalid URL:', url);
            return null;
        }
        const match = url.match(/athletes\/(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    };
    const extractTeamId = (url) => {
        if (typeof url !== 'string') {
            console.error('Invalid URL:', url);
            return null;
        }
        const match = url.match(/teams\/(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    };

    useEffect(() => {
        const fetchRoster = async () => {
            setWeeks(weekNumber);
        const firestore = getFirestore();
        const documentId = savedProfile.Email; // Replace with the actual document ID
        const QBx = doc(firestore, 'QBx', documentId);

        // Fetch the document
        const myQBx = await getDoc(QBx);

        if (myQBx.exists()) {
            // Document data
            const myQBxData = myQBx.data();

            setInfo(QBx);
            setCash(myQBxData.cash);
            setMyQBs(myQBxData.myQBs);
        }
        else {
            const defaultData = {
                cash: 100,
                myQBs: {} // Or any other default value for myQBs
            };

            await setDoc(QBx, defaultData);

            // Update local state with default values
            setInfo(QBx);
            setCash(defaultData.cash);
            setMyQBs(defaultData.myQBs);
        }
    }

    fetchRoster();
    }, [weekNumber]);

    useEffect(() => {
        const fetchAthletes = async () => {
            try {
                const currentGames = await fetch(`https://site.api.espn.com/apis/site/v2/sports/football/nfl/scoreboard?week=${weekNumber}&enable=roster,projection,stats`);
                const r = await currentGames.json();

                const activeTeams = {};
                r.events.forEach(data => {
                    activeTeams[data.competitions[0].competitors[0].id] = { state: data.status.type.state, week: data.week.number, name: data.competitions[0].competitors[1].team.displayName };
                    activeTeams[data.competitions[0].competitors[1].id] = { state: data.status.type.state, week: data.week.number, name: data.competitions[0].competitors[0].team.displayName };
                });
                console.log(activeTeams);

                const maxPages = 2;
                const weekUrls = [];
                for (let week = 1; week <= weeks; week++) {
                    for (let page = 1; page <= maxPages; page++) {
                        weekUrls.push(`https://sports.core.api.espn.com/v2/sports/football/leagues/nfl/seasons/${year}/types/${type}/weeks/${week}/qbr/10000?page=${page}`);
                    }
                }

                const firestore = getFirestore();
                const QBcollection = collection(firestore, 'QBx');
                const snapshot = await getDocs(QBcollection);
                const fetchedData = snapshot.docs.map(doc => doc.data());

                const keyFrequency = {};

                fetchedData.forEach(data => {
                    if (data.myQBs) {
                        Object.keys(data.myQBs).forEach(key => {
                            if (keyFrequency[key]) {
                                keyFrequency[key] += 1;
                            } else {
                                keyFrequency[key] = 1;
                            }
                        });
                    }
                });

                const totalFrequency = Object.values(keyFrequency).reduce((sum, count) => sum + count, 0);

                const responses = await Promise.all(weekUrls.map(url => fetch(url)));
                const qbData = await Promise.all(responses.map(response => response.json()));

                const Quarterbacks = [];
                await Promise.all(qbData.flatMap(async (weeks) => {
                    return Promise.all(weeks.items.map(async (entry) => {
                        const weekNumberMatch = extractWeekNumber(weeks.$ref);
                        var quarterbackRef = entry.athlete.$ref;

                        if (quarterbackRef.startsWith('http://')) {
                            quarterbackRef = quarterbackRef.replace('http://', 'https://');
                        }

                        const response = await fetch(quarterbackRef);
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }

                        const qb = await response.json();
                        const team = extractTeamId(qb.team.$ref);

                        if (!Quarterbacks[qb.id]) {
                            Quarterbacks[qb.id] = {};
                            Quarterbacks[qb.id]["qbr"] = {};
                            Quarterbacks[qb.id]["totalQBR"] = 0;
                            Quarterbacks[qb.id]["gp"] = 0;
                            Quarterbacks[qb.id]["owned"] = keyFrequency[qb.id];
                        }

                        Quarterbacks[qb.id]["qbr"][weekNumberMatch] = entry.splits.categories[0].stats[8]?.displayValue;
                        Quarterbacks[qb.id]["totalQBR"] += entry.splits.categories[0].stats[8]?.displayValue * 1;
                        Quarterbacks[qb.id]["fullName"] = qb.fullName;
                        Quarterbacks[qb.id]["image"] = qb.headshot;
                        Quarterbacks[qb.id]["gp"] += 1;
                        Quarterbacks[qb.id]["owned"] = keyFrequency[qb.id];
                        Quarterbacks[qb.id]["status"] = activeTeams[team]?.state;
                        Quarterbacks[qb.id]["team"] = activeTeams[team]?.name || "Bye Week";
                        Quarterbacks[qb.id]["week"] = activeTeams[team]?.week || 0;
                    }));
                }));

                setQuarterbacks(Quarterbacks);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching athletes:", error);
            }
        };

        fetchAthletes(); // Initial fetch

        // Set up interval to run fetchAthletes every 5 minutes
        const intervalId = setInterval(fetchAthletes, 5 * 60 * 1000); // 5 minutes in milliseconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);

    }, [weeks, myQBs]);

    
     const updateDB = async (info, mqb) => {
            const myQBx = await getDoc(info);
            const myQBxData = myQBx.data();

            setCash(myQBxData.cash);
        }

    //const [buttonText, setButtonText] = useState({});

    const buy = async (player, price, id, t, bprice) => {
        // Set the button text to "Processing"

        
        if (t == "bear") {
            //price = price;
        }
        try {
            
            if (myQBs === undefined || myQBs[id] === undefined) {
                
                if (cash - price >= 0) {

                    setButtonText(prevButtonText => ({
                        ...prevButtonText,
                        [id]: "Processing"
                    }));

                    // Update Firestore with the new cash value and player map
                    await updateDoc(info, {
                        cash: cash - price,
                        [`myQBs.${id}`]: { price: price, type: t } // Add the player with its price to the map in Firestore
                    });

                    // Update the local state
                    setMyQBs(prevMyQBs => ({
                        ...prevMyQBs,
                        [id]: { price: price, type: t }
                    }));
                    setCash(cash - price);

                    // Update the button text to "SELL"
                    setButtonText(prevButtonText => ({
                        ...prevButtonText,
                        [id]: "BUY"
                    }));
                }
            } else {

                setButtonText(prevButtonText => ({
                    ...prevButtonText,
                    [id]: "Processing"
                }));

                // Selling the player
                setCash(cash + price);

                // Update Firestore: Remove the player from the map
                await updateDoc(info, {
                    cash: cash + price,
                    [`myQBs.${id}`]: deleteField() // Remove the player from the map in Firestore
                });

                // Update the local state to remove the player
                setMyQBs(prevMyQBs => {
                    const updatedMyQBs = { ...prevMyQBs };
                    delete updatedMyQBs[id];
                    return updatedMyQBs;
                });

                // Update the button text to "BUY"
                setButtonText(prevButtonText => ({
                    ...prevButtonText,
                    [id]: "BUY"
                }));
            }
        } catch (error) {
            console.error("Error updating Firestore:", error);
            // Optionally, revert the button text to its previous state in case of an error
            setButtonText(prevButtonText => ({
                ...prevButtonText,
                [id]: myQBs !== undefined && myQBs[id] !== undefined ? "SELL" : "BUY"
            }));
        }
    };
    const toggleGraph = (id) => {
        if (toggle[id]) {
            setToggle(prevToggle => ({
                ...prevToggle,
                [id]: false
            }));
        }
        else {
         setToggle(prevToggle => ({
                    ...prevToggle,
                    [id]: true
                }));
        }
       
    }
const f = (f) => {
    setFilter(f);
    setLeaderboard(false);
}
const clear = () => {
    setSearch(null);
    searchRef.current.value = "";
}
    const [search, setSearch] = useState(null)
    const searchRef = useRef(null);
    const [leaderboard, setLeaderboard] = useState(false)
    const [board, setBoard] = useState({});
    
    useEffect(() => {
        const handleChange = () => {
            setSearch(searchRef.current.value);
        };

        const inputElement = searchRef.current;
        
        if(inputElement != null){
            inputElement.addEventListener('input', handleChange);
        }

        

        // Cleanup event listener on component unmount
        return () => {
            if(inputElement != null){
                inputElement.removeEventListener('input', handleChange);
             }
        };
    }, [searchRef, loading, leaderboard]);
    
   

    useEffect(() => {
        const fetchUserData = async () => {
    try {
        const firestore = getFirestore();
        const usersCollection = collection(firestore, 'Users');
        const snapshot = await getDocs(usersCollection);
        const fetchedData = snapshot.docs.map(doc => doc.data());
        const defaultObject = {cash: 100, myQBs: {}}

        const qbDataPromises = fetchedData.map(async (user) => {
            const documentId = user.Email;

            if (!documentId) {
                return defaultObject;
            }

            const QBxRef = doc(firestore, 'QBx', documentId);
            try {
                const qbDoc = await getDoc(QBxRef);

                if (qbDoc.exists()) {

                    return qbDoc.data();
                } else {
                    return defaultObject;
                }
            } catch (error) {
                return defaultObject;
            }
        });

        const qbData = await Promise.all(qbDataPromises);
        const combinedData = fetchedData.map((user, index) => ({
            ...user,
            qbData: qbData[index] 
        }));

        const dataWithTotalPrices = combinedData.map(user => {
            // Extract prices from myQBs object and sum them

            var totalPrice = 0;
            if(user.qbData.myQBs !== undefined && Object.values(user.qbData.myQBs).length > 0){
                totalPrice = Object.values(user.qbData.myQBs).reduce((sum, price) => sum + (price || 0), 0);
            }
            

            return {
                ...user,
                totalPrice // Add totalPrice to the user data
            };
        });

        // Update the state with combined data
        setBoard(dataWithTotalPrices);

    } catch (error) {
        console.log(error);
    }
};

// Call the function to fetch data
fetchUserData();
    }, [leaderboard]);

    if (loading) {
        return (
            <>
            <div className="page">Loading...</div>
            </>)
    }

    if(Object.keys(quarterbacks).length == 0 || false){
        return <div className="page help">
            <div className="profile-section"><h1>The QBx market will open soon.</h1></div>
            <div className="cash">
                Cash: ${cash}
            </div>
         </div>;
    }

   return (
       <div className="qbr-holder">
           <div className="search">
                    <input type="text" placeholder="Search" id="qbx-search" ref={searchRef} />
                    <div id="clearSearch" onClick={() => clear()}>
                    Clear</div>
                </div>
            <div className="filters">
                <div className={`filter ${filter == "all" ? "active" : ""}`} onClick={() => f("all")}>
                All QBs
                </div>
                
                <div className={`filter ${filter == "mine" ? "active" : ""}`} onClick={() => f("mine")}>
                My QBs
                </div>
            </div>
            <div className="update-banner">
            <p>QBx got an update! Check the rules if you have questions. If you were playing, sorry, as your progress will be lost. Thanks for understanding that it's still in beta -Benny</p>
            </div>
           <div id="playerQBR" className={`${filter}`}>
               <div className="quarterback main-info">
                   <div className="col-1"></div>
                   <div className="col-3 Buy"><div className="BuyButton" style={{ background: "transparent" }}>BUY</div></div>
                   <div className="col-3 Buy"><div className="BuyButton" style={{ background: "transparent" }}>SHORT</div></div>
               </div>

                {Object.entries(quarterbacks).map(([id, player]) => {

                    if(search != null){
                        if(!player.fullName.toLowerCase().includes(search.toLowerCase())){
                            return;
                        }
                    }

                    let totalQBR = player.totalQBR;
                    let numberOfWeeks = player.gp;

                    const maxKey = Math.max(...Object.keys(player.qbr).map(Number));
                    var lastQBR = player.qbr[maxKey];


                    var trend = "";

                    var mine = "";

                    if (maxKey > 0 && maxKey >= weekNumber-1) {

                        var prevQBR = player.qbr[maxKey - 1];
                        var diff = lastQBR - prevQBR;
                        diff = Math.round(diff);

                        if (diff > 0) {
                            diff = Math.abs(diff);
                            trend = `<span class='up'>&#11105;</span>`; // Upwards arrow
                        }
                        if (diff < 0) {
                            diff = Math.abs(diff);
                            trend = `<span class='down'>&#11107;</span>`; // Down arrow
                        }
                    }

                    //const price = Math.round((totalQBR / numberOfWeeks));
                    const price = Math.round(lastQBR);
                    var bearPrice = price;
                    const sell = myQBs !== undefined && myQBs[id] !== undefined;
                    var profit = 0;

                    if (sell) {
                        mine = "MINE";
                        console.log(bearPrice);
                        if(myQBs[id].type == "bear"){
                            profit = myQBs[id].price - (bearPrice);
                            bearPrice = (myQBs[id].price) + profit;
                        }
                        else {
                            profit = price - myQBs[id].price;
                            if(profit == NaN || myQBs[id].price == undefined){
                                profit = price - myQBs[id];
                            }
                        }
                    }

                    

                    var open = false;
                    if (player.status == "pre" || player.status == undefined || maxKey >= weekNumber || maxKey >= player.week) {
                        open = true;
                    }
                    if(price > 95 || price < 5){
                        //open = false;
                    }

                    return (
                        <div className={`quarterback ${mine} ${id}`} style={{ order: 1000 - price }} key={id}>
                            <div className="main-info" >
                                <div className="col-2" onClick={() => toggleGraph(id)}>    
                                    
                                    <div className="player" style={{ position: 'relative', height: '234px', height: 'auto' }}>
                                        <div className="currentGoingRate" dangerouslySetInnerHTML={{ __html: `${trend}${price}` }} />
                                    </div>
                                    
                                    {/*<div className="lastPlayed">{maxKey < weeks ? (weeks - maxKey == 1 ? "Missed this week" : "Last Played " + (weeks - maxKey) + " weeks ago") : player.gp < weeks - 3 || player.gp <= weeks / 2 ? "Plays in " + Math.round(player.gp / weeks * 100) + "% of Games" : ""}</div>*/}
                                    <div className="lastPlayed">Owned by {Math.round(player.owned*1) || 0}</div>
                                </div>
                                <div className="col-1" onClick={() => toggleGraph(id)}>    
                                    <img className="playerImage" src={player.image.href} />
                                    <div>
                                    <h2 onClick={() => toggleGraph(id)}>{player.fullName}</h2>
                                        <h3 onClick={() => toggleGraph(id)}>{player.team == "Bye Week" ? player.team : "vs " + player.team}</h3>
                                    </div>
                                </div>
                                {open ?
                                <div className="col-3">   
                                    <div className="Buy">
                                        <div
                                          className={`BuyButton ${sell ? "sell" : ""}`}
                                          style={{
                                            background: sell
                                              ? myQBs[id].type === "bear"
                                                ? "#A24936"
                                                : "#2C6E49"
                                              : "#2C6E49",
                                            color: "white"
                                          }}
                                          onClick={() => buy(player, sell && myQBs[id].type == "bear" ? bearPrice : price, id, sell ? myQBs[id].type : "buy")}
                                        >
                                          {buttonText[id] === "Processing" ? (
                                            "..."
                                          ) : (
                                            <>
                                              {sell ? (
                                                <>
                                                  {myQBs[id].type === "bear" ? (
                                                    <>
                                                      <span>+ {bearPrice}  &nbsp;</span>
                                                      {profit !== 0 && (
                                                        <span> 
                                                          {" "}
                                                          [<span className="" dangerouslySetInnerHTML={{ __html: profit >= 0 ? `&#11105; ${profit}` : `&#11107; ${-profit}` }} />]
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <>+ {price} &nbsp;</>
                                                      {profit !== 0 && (
                                                        <>
                                                           [<span className="" dangerouslySetInnerHTML={{ __html: profit >= 0 ? `&#11105; ${profit}` : `&#11107; ${-profit}` }} />]
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>

                                              ) : (
                                                `- ${price}`
                                              )}
                                            </>
                                          )}
                                        </div>

                                    </div>
                                    <span
                      dangerouslySetInnerHTML={{
                        __html: `&#11105;`,
                      }}
                    />

                                    </div> : <div className="closed">Unavailable</div>
                                }
                                {!sell && open ?
                                <div className="col-3">   
                                    <div className="Buy">
                                            <div className="BuyButton" style={{ background: "#A24936", color: "white"}} onClick={() => buy(player, price, id, "bear")}>
                                            {buttonText[id] === "Processing"
                                                ? "..."
                                                    : "-" + (price)}
                                        </div>

                                    </div>

                                </div> : null
                                }
                            </div>
                            {toggle[id] && <PlayerToggle QB={quarterbacks[id]} CurrentWeek={weeks} toggleGraph={toggleGraph} id={id} />}
                        </div>
                    );
                })}
            </div>
            <div className="cash">
            Points: {cash}
            </div>
        </div>
);

}

const PlayerToggle = ({ QB, CurrentWeek, id, toggleGraph }) => {
    // Prepare data for each week up to the current week
    const data = [];
    for (let week = 1; week <= 18; week++) {
        data.push({
            week: week,
            qbr: QB.qbr[week] ? parseFloat(QB.qbr[week]) : 0
        });
    }

    const barWidth = Math.min((0.9 * window.innerWidth - 4) / 18, 0.9*490 / 18);
    const barSpacing = 4;
    const barMaxHeight = 100; // max height in pixels for the bars
    const graphHeight = 100; // total height for the SVG graph

    const average = (QBdata, index) => {
        // Filter out weeks with missing QBR values up to the current index
        const validData = QBdata.slice(0, index + 1).filter(item => item.qbr !== 0);

        // Calculate the total QBR and average QBR
        const totalQBR = validData.reduce((sum, item) => sum + item.qbr, 0);
        const averageQBR = validData.length > 0 ? totalQBR / validData.length : 0;

        return averageQBR;
    }


    return (
        <div onClick={() => toggleGraph(id)}>
            <div className="graph">
                <svg>
                    {data.map(({ week, qbr }, index) => {

                        //const averageQBR = average(data, index);
                        
                        var averageQBR = data[index].qbr;
                        var ai = index;

                            while(ai > 0 && (averageQBR == 0)){
                                ai--;
                                averageQBR = data[ai]?.qbr;
                            }

                        qbr = averageQBR;
                        const cx = week * (barWidth) - 10;
                        const cy = 2 * ((100 - averageQBR) * 0.8 + 5);
                        const wn = (<text className="weekNumber" x={cx} y={195} fill="#999">
                            {week}
                        </text>);

                        if (index >= CurrentWeek) {
                            return (
                                <>

                                    <line x1={cx} x2={cx} y1={0} y2={185} stroke="#eee"
                                        strokeWidth="1" />{wn}
                                </>
                            );
                        }
                        

                        if (cy !== 170) {
                            // Find the last valid point where qbr was not 100
                            let previousPoint = null;
                            //previousPoint = average(data, index - 1);
                            var pi = index;

                            while(pi > 0 && (previousPoint == null || previousPoint == undefined || previousPoint == 0)){
                                pi--;
                                previousPoint = data[pi]?.qbr;
                                console.log(data[pi]);
                            }
                            console.log(previousPoint);
                            

                            if (previousPoint) {
                                const prevCx = (week - 1) * (barWidth) - 10;
                                const prevCy = 2 * ((100 - previousPoint) * 0.8 + 5);

                                return (
                                    <>
                                        
                                        <line x1={cx} x2={cx} y1={0} y2={185} stroke="#eee"
                                        strokeWidth="1" />
                                        
                                    <line
                                        key={`line-${index}`}
                                        x1={prevCx}
                                        y1={prevCy}
                                        x2={cx}
                                        y2={cy}
                                        stroke="black"
                                        strokeWidth="3"
                                    />{wn}</>
                                );
                            }
                        }
                        return (
                            <>
                                
                                <line x1={cx} x2={cx} y1={0} y2={185} stroke="#eee"
                                    strokeWidth="1" />{wn}
                            </>
                                );
                    })}

                    {/* Draw all the points and text on top of the lines */}
                    {data.map(({ week, qbr }) => {
                        //qbr = qbr > 50 ? 100 : 1;
                        const cx = week * (barWidth) - 10;
                        const cy = 2 * ((100 - qbr) * 0.8 + 5);

                        const qbra = average(data, week - 1);
                        const cy2 = 2 * ((100 - qbra) * 0.8 + 5);

                        if (cy !== 170) {
                            return (
                                <g key={`point-${week}`}>
                                    {/*<circle cx={cx} cy={cy2} r="7" fill="grey" />
                                    <text className="faded"
                                        x={cx}
                                        y={cy2 + 3.5} // Position text slightly below the center of the circle
                                        fill="#ddd"
                                        textAnchor="middle"
                                    >{Math.round(qbra)}</text>*/}
                                    <circle cx={cx} cy={cy} r="9" fill="black" />
                                    
                                    <text
                                        x={cx}
                                        y={cy + 3.5} // Position text slightly below the center of the circle
                                        fill="white"
                                        textAnchor="middle"
                                    >
                                        {Math.round(qbr)}
                                    </text>
                                </g>
                            );
                        }
                        return null;
                    })}
                </svg>



            </div>
            {/*<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                {data.map(({ index, week }) => (
                    <div key={week} style={{ textAlign: 'center', marginTop: '5px', width: '24px', flexGrow: 1 }}>
                        {CurrentWeek >= week ? week : ""}
                    </div>
                ))}
            </div>*/}
        </div>
    );
}
export { Auction, QBxLeaders };
